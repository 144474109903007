import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import LoginView from '../views/LoginForm.vue'
import configfile from '@/config'
import { useAuthStore } from '@/stores';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/appeal',
    name: 'appeal',
    component: () => import('../views/appeal/AppealView.vue'),
  },
  {
    path: '/appeal/new',
    component: () => import('../views/appeal/NewAppealView.vue'),
  },
  {
  path: '/appeal/expectation',
    component: () => import('../views/appeal/ExpectationAppealView.vue'),
  },
  {
    path: '/appeal/answered',
    component: () => import('../views/appeal/AnsweredAppealView.vue'),
  },
  {
    path: '/appeal/completed',
    component: () => import('../views/appeal/CompletedAppealView.vue'),
  },
  {
    path: '/appeal/overdue',
    component: () => import('../views/appeal/OverdueAppealView.vue'),
  },

  {
    path: '/users',
    name: 'users',
    component: () => import('../views/users/UsersView.vue'),
  },
  {
    path: '/users/all',
    component: () => import('../views/users/AllUsersView.vue'),
  },
  {
    path: '/users/profile/:userId',
    name: 'users/profile',
    component: () => import('../views/users/ProfileView.vue'),
  },
  {
    path: '/users/moderator/:userId',
    name: 'users/moderator',
    component: () => import('../views/users/ModeratorView.vue'),
  },
  {
    path: '/organization',
    name: 'organization',
    component: () => import('../views/organization/OrganizationView.vue'),
  },
  {
    path: '/organization/all',
    component: () => import('../views/organization/AllOrganizationView.vue'),
  },

  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/settings/SettingsView.vue'),
  },
  {
    path: '/settings/category',
    component: () => import('../views/settings/CategoryView.vue'),
  },
  {
    path: '/settings/subcategory',
    component: () => import('../views/settings/SubCategoryView.vue'),
  },

  
  {
    path: '/reference',
    component: () => import('../views/HandbookView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/settings/sprorgtype',
    component: () => import('../views/settings/SprOrgTypeView.vue'),
  },
  {
    path: '/action/:appeal_id',
    name: 'action',
    component: () => import('../views/ActionView.vue'),
  },
  {
    path: '/actiontmp/:appeal_id',
    name: 'actiontmp',
    component: () => import('../views/ActionTmpView.vue'),
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../views/statistics/StatView.vue'),
  },
  {
    path: '/statistics/appeals',
    component: () => import('../views/statistics/AppealsStatView.vue'),
  },
  {
    path: '/statistics/moderators',
    component: () => import('../views/statistics/ModeratorsStatView.vue'),
  },
  {
    path: '/statistics/users',
    component: () => import('../views/statistics/UsersStatView.vue'),
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  }
]

const router = createRouter({
  history: createWebHashHistory(configfile.apiUrl),
  routes
})


router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.user) {
      auth.returnUrl = to.fullPath;
      //return '/login';
  }
});

export default router;
// router.beforeEach((to, from, next) => {
//   // redirect to login page if not logged in and trying to access a restricted page
//   const publicPages = ["/login"];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem("user");

//   if (authRequired && !loggedIn) {
//     return next("/login");
//   }

//   next();
// });



// import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";

// const routes = [
//   {
//     path: "/",
//     name: "home",
//     component: HomeView,
//   },
//   {
//     path: "/about",
//     name: "about",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
//   },
// ];

// const router = createRouter({
//   // eslint-disable-next-line no-undef
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });

// export default router;
