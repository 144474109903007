// временный файл, который надо скопировать в config.js
// вставить нужные для локальной разработки данные
// ниже приведен состав файла
const config = {
  /* eslint-disable  no-unused-vars */
  //apiUrl: "http://10.14.17.132:8080/api",
  // apiUrl: "http://localhost:80/api",
  //apiUrl: "http://10.9.0.5/api",
  //apiUrl: "http://192.168.100.4:8001/api",
  //apiUrl: "https://supportmanager.edus.kz/back/api",
  //apiUrl: "http://api.lsupportmanager.loc/api",
  apiUrl: "https://api.supportmanager.edus.kz/api",
};
export default config;
