 <template>
  <div>
    <side-bar v-if="curpage.user" />
    <login-form v-if="!curpage.user" />
  </div>
</template>

<script setup>
import SideBar from "./components/SideBar.vue";
import LoginForm from "./views/LoginForm.vue"
import { useAuthStore } from '@/stores';
//import { ref } from 'vue';

const curpage = useAuthStore();
//console.log(curpage);
</script>

<style lang="scss">
#app {
  font-family: "Inter", sans-serif;
  margin-top: 60px;
}
.v-main {
  padding-top: 0 !important;
}
.v-application__wrap {
  min-height: 100% !important;
}
</style>
