<template>
  <div class="section__header">
    <div class="section__header__title">Часто задаваемых вопросов</div>
    <v-btn class="button_add" @click="addPost"> Добавить вопрос </v-btn>
  </div>
  <div class="search__filter">
    <input
      class="search__filter__input"
      v-model="search"
      placeholder="Поиск"
      style="border-radius: 4px; height: 55px"
    />
  </div>
  <div>
    <label class="universal__label">Выберите категорию</label>
    <v-select
      v-model="service_id"
      :items="[allCategory, ...categories]"
      item-title="name"
      item-value="id"
      variant="outlined"
    ></v-select>
  </div>

  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12" v-if="filteredPosts.length === 0">
            <v-alert>Нет воросов</v-alert>
          </v-col>
          <v-col
            class="card__body"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            v-for="(post, index) in displayedPosts"
            :key="post.id"
          >
            <post
              :post="post"
              :service="categories_array"
              @edit="editPost(index)"
              @delete="deletePost(index)"
            ></post>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-pagination
              v-model="page"
              :length="numPages"
              @input="goToPage"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <div class="post__dialog__title">
            {{ editing ? "Редактировать вопрос" : "Добавить вопрос" }}
          </div>

          <v-card-text>
            <div>
              <label class="universal__label">Вопрос</label>
              <v-text-field
                v-model="question_ru"
                variant="outlined"
                placeholder="Введите вопрос..."
                @input="isChange = isChange=='none' ? 'changed' : isChange"
              ></v-text-field>
            </div>
            <div>
              <label class="universal__label">Сұрақ</label>
              <v-text-field
                v-model="question_kk"
                variant="outlined"
                placeholder="Введите вопрос..."
                @input="isChange = isChange=='none' ? 'changed' : isChange"
              ></v-text-field>
            </div>
            <div>
              <label class="universal__label">Категория</label>
              <v-select
                v-model="service_id"
                :items="categories"
                item-title="name"
                item-value="id"
                variant="outlined"
                placeholder="Выберите категорию..."
                @update:model-value="selectCategory($event)"
              ></v-select>
            </div>
            <div>
              <label class="universal__label">Подкатегория</label>
              <v-select
                v-model="subcategory_id"
                :items="subcategory"
                item-title="name"
                item-value="id"
                variant="outlined"
                placeholder="Выберите категорию..."
                @update:model-value="isChange = isChange=='none' ? 'changed' : isChange"
              ></v-select>
            </div>
            <div>
              <label class="universal__label">Ответ</label>
              <v-textarea
                v-model="answer_ru"
                variant="outlined"
                placeholder="Введите ответ..."
                @input="isChange = isChange=='none' ? 'changed' : isChange"
              ></v-textarea>
            </div>
            <div>
              <label class="universal__label">Жауап</label>
              <v-textarea
                v-model="answer_kk"
                variant="outlined"
                placeholder="Введите ответ..."
                @input="isChange = isChange=='none' ? 'changed' : isChange"
              ></v-textarea>
            </div>
          </v-card-text>
          <div class="post__dialog__actions">
            <v-btn style="background: #bbbbbb" @click="closeDialog"
              >Отмена</v-btn
            >
            <v-btn v-if="editing" style="background: #27ae60" @click="savePost"
              >Сохранить</v-btn
            >
            <v-btn v-if="!editing" style="background: #0075ff" @click="savePost"
              >Добавить</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <div class="dialog__container">
            <div class="dialog__container__close">
              <v-icon size="small" class="me-2" @click="closeDelete">
                mdi-window-close
              </v-icon>
            </div>
            <div class="dialog__title">Уверены в том что хотите удалить?</div>

            <div class="dialog__actions">
              <v-btn
                class="btn__custom"
                style="background: #bbbbbb"
                @click="closeDelete"
              >
                Отмена
              </v-btn>
              <v-btn
                class="btn__custom"
                style="background: red"
                @click="deleteItemConfirm"
              >
                Удалить
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
  <v-overlay
      :model-value="overlay"
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
</template>

<script>
import Post from "../components/PostComponent.vue";
import configfile from "@/config";
import { fetchWrapper } from '@/helpers';
import router from '@/router';
import { useAlertStore } from '@/stores';
import { useAuthStore } from '@/stores';


export default {
  components: {
    Post,
  },
  data() {
    return {
      avtor_by: useAuthStore().user.user.id,
      user_by: null,
      baseUrl: `${configfile.apiUrl}`,
      err: useAlertStore(),

      overlay:false,
      id: null,
      posts: [],
      categories: [],
      categories_array: [],
      subcategories_array: [],
      subcategory: [],
      isChange: null,
      allCategory: {id: 0, name: "All"},
      search: "",
      service_id: 0,
      subcategory_id: 0,
      dialog: false,
      editing: false,
      title: "",
      body: "",
      question_kk: "", 
      question_ru: "", 
      answer_kk: "", 
      answer_ru: "",
      editedIndex: -1,
      page: 1,
      itemsPerPage: 6,
      dialogDelete: false,
      deleteIndex: null,
    };
  },
  computed: {
    filteredPosts() {
      let filtered = this.posts;
      if (this.search) {
        filtered = filtered.filter((post) =>
          post.title.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.service_id != 0) {
        filtered = filtered.filter((post) => post.service_id === this.service_id);
      }
      return filtered;
    },
    numPages() {
      return Math.ceil(this.filteredPosts.length / this.itemsPerPage);
    },
    displayedPosts() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredPosts.slice(start, end);
    },
  },
  methods: {
    addPost() {
      this.dialog = true;
      this.editing = false;
      this.question_kk = "", 
      this.question_ru = "", 
      this.answer_kk = "", 
      this.answer_ru = "",
      this.service_id = null;
      this.subcategory_id = null;
      this.isChange = "new";
      this.user_by = this.avtor_by;
    },
    editPost(index) {
      const post = this.filteredPosts[index];
      this.dialog = true;
      this.editing = true;
      this.id = post.id;
      this.question_kk = post.question_kk;
      this.question_ru = post.question_ru;
      this.answer_kk = post.answer_kk;
      this.answer_ru = post.answer_ru;
      this.service_id = post.service_id;
      this.subcategory = this.subcategories_array[this.service_id];
      this.subcategory_id = post.subcategory_id;
      this.user_by = post.user_by;
      this.isChange = post.isChange;
      this.editedIndex = this.posts.indexOf(post);
    },
    savePost() {

      if (this.isChange !="none") {
        this.setPostSave();
      } 
      this.dialog = false;
    },
    closeDialog(){
      this.service_id = 0;
      this.dialog = false;
    },
    deletePost(index) {
      this.deleteIndex = index;
      this.dialogDelete = true;
    },
    closeDelete(){
      this.deleteIndex = null;
      this.dialogDelete = false;
    },
    deleteItemConfirm(){
      if(this.deleteIndex !== null){
        const post = this.filteredPosts[this.deleteIndex];
        this.setPostDelete(post.id);
      }
      this.deleteIndex = null;
      this.dialogDelete = false;
    },
    goToPage(page) {
      this.page = page;
    },
    selectCategory(item){
      this.isChange = this.isChange =='none' ? 'changed' : this.isChange;
      this.subcategory_id = null;
      this.subcategory = this.subcategories_array[item] ?? {};
    },

    //Начальные данные
    async getList(){
      const err = this.err;

      this.overlay=true;
      const result = await fetchWrapper.post(`${this.baseUrl}/auth/referencebook/list`, {
        user_by: this.avtor_by,
        });

      const arr = result.data;

      if ( typeof arr !== undefined && arr !== null && Object.keys(arr).length > 0 ) {
        this.posts = arr;
        
        this.categories = [];
        this.categories_array = [];
        this.subcategories_array = [];
        this.subcategory = [];
        
        result.service.forEach(element => {
          this.categories.push({id: element['id'], name: element['name']});
          this.categories_array[element['id']] = element['name'];
        });

        result.subcategoriquestion.forEach( element => {
          this.subcategories_array[element.category_id] = this.subcategories_array[element.category_id] || [];
          this.subcategories_array[element.category_id].push({id: element.id, name: element.name}); 
        });
        //console.log(this.subcategories_array);
      }else{
        err.error("Нет данных");
        router.push('/users/all');
      }
      this.overlay = false;
    },
    async setPostSave(){

    const alert = this.err;

    await fetchWrapper.post(`${this.baseUrl}/auth/referencebook/store`, {
      id: this.id,
      question_kk: this.question_kk, 
      question_ru: this.question_ru, 
      answer_kk: this.answer_kk, 
      answer_ru: this.answer_ru,
      service_id: this.service_id,
      subcategory_id: this.subcategory_id,
      user_by: this.isChange == "new" ? this.avtor_by : this.user_by,
      isChange: this.isChange,

    }).catch(function (errors) {
        Object.values(errors).forEach(elem=>{
          alert.error(Object.values(elem));
        })
          return;
      }).then( (item) => {
        if( item !== undefined && item !== null && item.status !== undefined && item.status == 201){
          alert.success(item.message);
          this.getList();
        }
        
      });
    },

    async setPostDelete(id){

      const alert = this.err;

      await fetchWrapper.post(`${this.baseUrl}/auth/referencebook/delete`, {
        id: id,
      }).catch(function (errors) {
          Object.values(errors).forEach(elem=>{
            alert.error(Object.values(elem));
          })
            return;
        }).then( (item) => {
          if( item !== undefined && item !== null && item.status !== undefined && item.status == 201){
            alert.success(item.message);
            this.getList();
          }
          
        });
    }
  },
  mounted() {
    this.getList();
    //this.editedUser = JSON.parse(JSON.stringify(this.user));
  },
};
</script>
