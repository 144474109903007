<template>
  <div class="home">
    <div class="section__statistics">
      <div class="section__statistics__content">
        <div class="statistics__item">
          <div class="statistics__item__title">Статистика обращений</div>
          <div class="statistics__item__content">
            <div class="rectangle" style="background: #fb8500">
              <span>Запросы за все время</span>
              <p>{{ statdata.length > 0 ? statdata[0].alls : 0 }}</p>
            </div>
            <div class="rectangle" style="background: #00ca5d">
              <span>Завершенные запросы</span>
              <p>{{ statdata.length > 0 ? statdata[0].ended : 0 }}</p>
            </div>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__item__title">Статистика по месяцам</div>
          <div
            class="statistics__item__content"
            style="display: inline; max-height: 300px">
            <div>
              <apexchart
                ref="chart"
                :options="monthStat"
                :series="monthSeries"
                type="line"
                height="350"
                :dataLabels="monthLabels" />
            </div>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__item__title">Запросы на сегодня</div>
          <div
            class="statistics__item__content"
            style="display: inline; max-height: 300px">
            <apexchart
              type="pie"
              ref="chartCurday"
              :options="dayOptions"
              :series="daySeries"
              :height="200"></apexchart>
          </div>
        </div>
        <div class="statistics__item">
          <div class="statistics__item__title">
            Общая статистика запросов по категориям
          </div>
          <div
            class="statistics__item__content"
            style="display: inline; max-height: 300px">
            <apexchart
              type="pie"
              ref="chartCategory"
              :options="categoriesOptions"
              :series="categoriesSeries"
              :height="200"></apexchart>
          </div>
        </div>
      </div>
    </div>
    <br />
    <h3 style="text-align: left">Последние действия</h3>
    <br />

    <v-card>
      <!-- eslint-disable vue/no-v-model-argument -->
      <v-data-table
        :headers="headers"
        :items="data_tables"
        v-model:page="page"
        :items-per-page="itemsPerPage"
        hide-default-footer>
        <template v-slot:bottom> </template
      ></v-data-table>
    </v-card>
  </div>
  <div class="pagination__section">
    <v-pagination
      v-model="page"
      :length="Math.ceil(this.data_tables.length / this.itemsPerPage)"
      :total-visible="4"></v-pagination>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import configfile from "@/config";
import { fetchWrapper } from '@/helpers';

export default {
  name: "HomeView",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      statdata: {},
      curdate: 0,
      cateorypie: 0,
      tabledata: {},
      page: 1,
      itemsPerPage: 5,
      //daystat
      dayOptions: {
        chart: {
          type: "pie",
        },
        title: {
          text: "Общее количество запросов 0",
          align: "right",
          style: {
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: "normal",
          },
        },
        labels: [
          "Новые обращения",
          "Ожидающие обращения",
          "Отвеченные обращения",
          "Завершенные обращения",
        ],
        colors: ["#3A54E9", "#2EDCC3", "#D0DF1A", "#FD4141"],
        legend: {
          position: "right",
        },
      },
      daySeries: [0,0,0,0],
      //monthStat
      monthStat: {
        xaxis: {
          categories: [
            "янв",
            "фев",
            "мар",
            "апр",
            "май",
            "июн",
            "июл",
            "авг",
            "сен",
            "окт",
            "ноя",
            "дек",
          ],
        },
        yaxis: {
          min: 0,
          max: 100,
        },
      },
      monthSeries: [
        {
          name: "Новые обращения",
          data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          color: "#FFAD62",
        },
        {
          name: "Отвеченные обращения",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
          color: "#304BE4",
        },
      ],
      monthLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          colors: ["#333"],
        },
        formatter: function (val) {
          return val + "%";
        },
      },
      //categoriesStat
      categoriesOptions: {
        chart: {
          type: "pie",
        },
        title: {
          text: "Общее количество запросов 0",
          align: "right",
          style: {
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: "normal",
          },
        },
        labels: [
          "Электронная школа(Mektep.EDUS)",
          "Электронный колледж(College.EDUS)",
          "Дополнительное образование(Zerek.EDUS)",
          "Другие платформы EDUS",
        ],
        colors: ["#6A3CB5", "#BAE969", "#D0DF1A", "#FD4141"],
        legend: {
          position: "right",
        },
      },
      categoriesSeries: [0,0,0,0],

      headers: [
        {
          align: "start",
          key: "data",
          sortable: false,
          title: "Дата",
        },
        { key: "managers", title: "Менеджер", sortable: false },
        { key: "action", title: "Действие", sortable: false },
        { key: "appeal_id", title: "Номер обращений", sortable: false },
        { key: "category", title: "Категория запроса", sortable: false },
        { key: "created_data", title: "Дата создания", sortable: false },
      ],
      data_tables: [],
    };
  },
  methods: {
    async getStatistic(){
      const baseUrl = `${configfile.apiUrl}`;
      const result = await fetchWrapper.post(`${baseUrl}/auth/header-statistic`, {});
      if ( typeof result.data !== "undefined" && result.data !== null && result.data.length > 0 ) {
        this.statdata = result.data;
        const vol = result.data[0];
        this.monthSeries[0].data =
        [
          vol.m1,
          vol.m2,
          vol.m3,
          vol.m4,
          vol.m5,
          vol.m6,
          vol.m7,
          vol.m8,
          vol.m9,
          vol.m10,
          vol.m11,
          vol.m12
        ];
        this.monthSeries[1].data =
        [
          vol.end1,
          vol.end2,
          vol.end3,
          vol.end4,
          vol.end5,
          vol.end6,
          vol.end7,
          vol.end8,
          vol.end9,
          vol.end10,
          vol.end11,
          vol.end12
        ];
        this.curday =  parseInt(vol.curday1) +  parseInt(vol.curday2) +  parseInt(vol.curday3) +  parseInt(vol.curday4);
        this.$refs.chartCurday.updateOptions({ title: {text: [ "Общее количество запросов " + this.curday]}  });
        if( this.curday == 0 ){
          this.daySeries = [100,0,0,0];
          this.$refs.chartCurday.updateOptions({ labels: ["Нет данных", "", "", ""],  });

        }else{
          this.daySeries =
        [
          Math.round(vol.curday1 * (100/this.curday)),
          Math.round(vol.curday2 * (100/this.curday)),
          Math.round(vol.curday3 * (100/this.curday)),
          Math.round(vol.curday4 * (100/this.curday)),
        ];
        }

        this.cateorypie = parseInt(vol.service1) +  parseInt(vol.service2) +  parseInt(vol.service3) +  parseInt(vol.service4);
        this.$refs.chartCategory.updateOptions({title: {text: [ "Общее количество запросов " + this.cateorypie]}  });

        if( this.cateorypie == 0 ){
          this.categoriesSeries = [100,0,0,0];
          this.$refs.chartCategory.updateOptions({ labels: ["Нет данных", "", "", ""],  });

        }else{
          this.categoriesSeries =
        [
            Math.round(vol.service1 * (100/this.cateorypie)),
            Math.round(vol.service2 * (100/this.cateorypie)),
            Math.round(vol.service3 * (100/this.cateorypie)),
            Math.round(vol.service4 * (100/this.cateorypie)),
        ];
        }
      }else{
        this.daySeries = [100,0,0,0];
        this.$refs.chartCurday.updateOptions({ labels: ["Нет данных", "", "", ""],  });

        this.categoriesSeries = [100,0,0,0];
        this.$refs.chartCategory.updateOptions({ labels: ["Нет данных", "", "", ""],  });
      }
    },
    async getLastaction(){
      const baseUrl = `${configfile.apiUrl}`;
      const result = await fetchWrapper.post(`${baseUrl}/auth/header-lastaction`, {});
      //console.log( typeof result.data !== "undefined" && result.data !== null && result.data.length > 0 );
      if ( typeof result.data !== "undefined" && result.data !== null && result.data.length > 0 ) {
        var arr =  Object.values(result.data);
        this.data_tables.length = 0;
        arr.map( (element) => {
          this.data_tables.push(element)
        });

      }

  }

  },
  async mounted() {
    this.getStatistic();
    this.getLastaction();

  },
};

</script>

<style lang="scss">
.section__statistics {
  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .statistics__item {
    text-align: left;
    width: 48%;
    margin-bottom: 30px;
    @media only screen and (max-width: 1268px) {
      width: 100%;
    }
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      margin-bottom: 20px;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .rectangle {
        width: 48%;
        padding: 10px;
        text-align: center;
        border-radius: 10px;
        span {
          font-weight: 400;
          font-size: 1.1vw;
          text-align: center;
          color: #ffffff;
          @media only screen and (max-width: 1068px) {
            font-size: 20px;
          }
        }
        p {
          font-weight: 700;
          font-size: 2.2vw;
          color: #ffffff;
          @media only screen and (max-width: 1068px) {
            font-size: 36px;
          }
        }
      }
    }
  }
}
</style>
