import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from 'pinia';
const pinia = createPinia();

import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import 'vuetify/dist/vuetify.css';
import '@/styles/variables.scss';

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

// VDataTable 
import { VDataTable } from 'vuetify/labs/VDataTable';

const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi", // This is already the default value - only for display purposes
  },
  components,
  directives,
});

createApp(App).use(router).use(pinia).use(vuetify).component('VDataTable', VDataTable).mount("#app");
