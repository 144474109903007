<template>
  
  <form @submit.prevent="onSubmit">
    <div class="py-4">
      
      <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
      >
        <alert-tag />
        <div class="text-subtitle-1 text-medium-emphasis">Эл.почта</div>

        <v-text-field
          density="compact"
          placeholder="Email aдрес"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          v-model="email"
          name="email"
        ></v-text-field>

        <div
          class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
        >
          Пароль

          <a
            class="text-caption text-decoration-none text-blue"
            rel="noopener noreferrer"
            target="_blank"
            disabled="disabled"
          >
            Забыли пароль?</a
          >
        </div>

        <v-text-field
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Введите пароль"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          v-model="password"
          name="password"
          @click:append-inner="visible = !visible"
        ></v-text-field>

        <v-card class="mb-12" color="surface-variant" variant="tonal">
          <v-card-text class="text-medium-emphasis text-caption">
            Внимание: После 3 неудачных попыток авторизации ваш аккаунт будет временно заблокирован на 3 часа. Вы также можете сбросить пароль, нажав на ссылку "Забыли пароль?".
          </v-card-text>
        </v-card>

        <v-btn
          block
          class="mb-8"
          color="blue"
          size="large"
          variant="tonal"
          type="submit"
          :disabled="email == '' || password  == ''"
        >
        <span class="spinner-border spinner-border-sm mr-1"></span>
         Вход
        </v-btn>

        <v-card-text class="text-center">
          <a
            class="text-blue text-decoration-none"
            rel="noopener noreferrer"
            target="_blank"
          >
            Зарегистрироваться <v-icon icon="mdi-chevron-right"></v-icon>
          </a>
        </v-card-text>
      </v-card>
    </div>
  </form>
</template>

<script setup>
import { useAuthStore } from '@/stores';
import AlertTag from '@/components/AlertTag.vue'
import {ref} from 'vue';

const email = ref("");
const password= ref("");
//const submitted = false;
const visible = ref(false);

//const authStore = useAuthStore();
//authStore.logout();


function onSubmit() {
    const authStore = useAuthStore();
    //const { username, password } = values;

    return authStore.login(email.value, password.value)
        .catch(error => console.log(error));
}


</script>

<script>
// export default {
//   // data() {
//   //   return {
//   //     username: "",
//   //     password: "",
//   //     submitted: false,
//   //     visible: false,
//   //   };
//   // },
//   // computed: {
//   //   loggingIn() {
//   //     return this.$store.state.authentication.status.loggingIn;
//   //   },
//   // },
//   // created() {
//   //   // reset login status
//   //   this.$store.dispatch("authentication/logout");
//   // },
//   // methods: {
//   //   handleSubmit() {
//   //     this.submitted = true;
//   //     const { username, password } = this;
//   //     const { dispatch } = this.$store;
//   //     if (username && password) {
//   //       dispatch("authentication/login", { username, password });
//   //     }
//   //   },
//   // },
// };
// </script>
