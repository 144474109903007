<script setup>
import { storeToRefs } from 'pinia';

import { useAlertStore } from '@/stores';

const alertStore = useAlertStore();
const { alert } = storeToRefs(alertStore);

</script>

<template>
    <transition name="fade">
        <div v-if="alert" class="container" style="position: relative; border-radius: 10px; padding-bottom: 20px;">
        <div class="m-3">
            <v-alert 
                variant="tonal"
                :type="alert.type"
                border="start"
                >
                {{alert.message}}
            </v-alert>
        </div>
        <div style="position: absolute; right: -5px; top: -5px;">
            <v-btn density="compact" size="small" icon="mdi-close" @click="alertStore.clear()"></v-btn>
        </div> 
    </div>
    
    </transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>